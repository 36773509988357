.login {
    width: 100%;
  .prompt {
    max-width: 400px;
    box-sizing: border-box;
    margin: 50px auto;
    width: 100%;
    padding: 16px;
    .logo {
      font-family: "Lobster", sans-serif;
      font-size: 2.3em;
      margin-bottom: 18px;
      text-align: center;
    }
    .error {
      visibility: hidden;
      margin-bottom: 8px;
      color: red;
      text-align: center;
      &.show {
        visibility: visible;
      }
    }
    .form {
      .form-group {
        display: flex;
        margin-bottom: 16px;
        align-items: center;
        .icon {
          flex-basis: 36px;
          vertical-align: center;
        }
        .control {
          flex-grow: 1;
          input {
            outline: none;
            width: 100%;
            line-height: 1.1;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 12px 14px;
            font-size: 1em;
            border: 1px solid #ccc;
            &:disabled {
              background: #eee;
              color: #999;
            }
          }
        }
      }
      .actions {
        .btn {
          display: block;
          width: 100%;
        }
      }
    }
  }

  @media(max-height: 500px) {
    display: block;
    .prompt {
      margin: 0 auto;
    }
  }
}