.Filter {
  box-sizing: border-box;
  padding: 6px 6px 6px 10px;
  border-radius: 30px;
  background: rgba(0,0,0,0.2);
  margin: 0 4px 4px 0;
  display: flex;
  align-items: center;
  .label {
    line-height: 1;
    font-size: 0.9em;
  }
  .icon {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 12px;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: rgba(255,255,255,0.2);
    border-radius: 50%;
  }
}