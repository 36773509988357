.SearchCandidate {
  display: flex;
  align-items: center;
  background-color: var(--class);
  padding: 12px;
  margin-bottom: 1px;
  font-size: 0.9em;
  .icon {
    flex: 0 0 30px;
    >span {
      font-size: 18px;
      line-height: 12px;
      vertical-align: middle;
    }
  }
  .label {

  }
}