.settings {
  .items {
    .item {
      padding: 12px;
      border-bottom: 1px solid var(--bottom-navigation);
      &:last-child {
        border-bottom: none;
      }

      .item-header{
        margin-bottom: 6px;
      }
      .item-body {
        font-size: 0.9em;
        margin-bottom: 12px;
        opacity: 0.6;
        line-height: 1.3;
        &:last-child {
          margin-bottom: 0;
        }
        p {
          margin: 0 0 8px;
        }
        p:last-child {
          margin-bottom: 0;
        }
      }

      .btn-link {
        border: none;
        background: none;
        padding: 0;
        font-size: 0.9em;
        line-height: 1.2;
        border-radius: 0;
        color: var(--accent);
        border-bottom: 1px dashed var(--accent);
      }
    }
  }

  .version {
    margin: 12px 0 12px;
    padding: 0 12px;
    font-size: 0.9em;
    opacity: 0.3;
  }
}