.SearchResult {
  /** some padding below looks like there are more to load **/
  padding-bottom: 48px;
  transition: opacity 0.2s;
  position: relative;
  &:after {
    content: 'Loading...';
    display: none;
    position: fixed;
    bottom: 75px;
    left: 50%;
    margin-left: -40px;
    background: rgba(0,0,0,0.6);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.9em;
    color: white;
    z-index: 1000;
    transition: opacity 0.2s;
  }

  &.loading {
    .holder {
      opacity: 0.7;
    }
    &:after {
      display: block;
    }
  }
}