:root {
  --background: white;
  --text-primary: #13191c;
  --text-secondary: var(--accent);
  --button: #ddd;
  --accent: #1e88e5;
  --app-bar: var(--accent);
  --app-bar-text: white;
  --bottom-navigation: #e5e5e5;
  --day: #ddd;
  --class: #eee;
  --class-waitlisted: #ffefbd;
  --class-booked: #d2ebb5;
  --class-suspended: #f6db87;
}

body {
  margin: 0;
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: var(--text-primary);
  background-color: var(--background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn {
  outline: none;
  white-space: nowrap;
  background-color: var(--button);
  border-radius: 4px;
  border: 1px solid var(--button);
  padding: 12px 16px;
}

.btn:disabled {
  background: #b0d5f3;
  color: #617f9b;
  border-color: #66a6e5;
}

.btn-primary {
  background: #2196f3;
  color: white;
  border-color: #1976d2;
}
