$appBarHeight: 52px;
$bottomNavigationBarHeight: 60px;

.navigation {
  .navigation-holder {
    .navigation-tab {
      padding-top: $appBarHeight;
      padding-bottom: $bottomNavigationBarHeight;
      display: none;
      transition: all 0.2s;

      &.active {
        display: block;
      }
    }
  }

  .app-bar {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    transition: top 0.2s;
  }

  .bottom-navigation {
    position: fixed;
    z-index: 105;
    bottom: 0;
    left: 0;
    right: 0;
    transition: bottom 0.2s;
  }

  //&.down {
  //
  //  .app-bar {
  //    top: -$appBarHeight;
  //  }
  //  .bottom-navigation {
  //    bottom: -$bottomNavigationBarHeight;
  //  }
  //}
}
