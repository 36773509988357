.ScheduleButton {
  position: relative;

  .Button {
    display: flex;
    align-items: center;
    .lbl {
      white-space: nowrap;
    }
    .icon {
      margin: -6px -4px -6px 2px;
      font-size: 16px;
    }
  }

  .btn-add {
    background: #546e7a;
    color: white;
  }
  .btn-toggle {
    color: white;

    .icon {
      transition: all 0.2s;
    }

    &.booking {
      background: #0288d1;
    }

    &.suspending {
      background: #e38904;
      color: black;
    }
  }

  .dd {
    position: absolute;
    top: 32px;
    z-index: 8;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0,0,0,0.5);
    background: var(--bottom-navigation);

    .dd-item {
      .btn {
        white-space: nowrap;
        font-size: 0.9em;
        background: var(--bottom-navigation);
        color: var(--text-primary);
        border-radius: 0;
        padding: 8px 12px 8px 8px;
        border: 0;
        display: block;
        width: 100%;
        text-align: left;
        border-left: 3px solid transparent;
      }

      &.active {
        .btn {
          color: var(--text-primary);
          background: var(--background);
          border-left: 4px solid var(--accent);
        }
      }
    }

    .dd-item.delete {
      .btn {
        color: #f44336;
      }
    }

    .dd-separator {
      border-bottom: 1px solid var(--background);
    }
  }

  &.show-dropdown {
    .btn-toggle .icon {
      transform: rotate(180deg);
    }
  }

  &.show-dropdown.dropdown-overflowed {
    .dd {
      bottom: 36px;
      top: auto;
    }
  }

  @keyframes dd-opening {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &.opening {
    .dd {
      opacity: 0;
      animation: dd-opening 0.1s;
      animation-fill-mode: forwards;
      //animation-timing-function: ease-out;
    }
  }

  &.closing {
    .dd {
      opacity: 1;
      animation: dd-opening 0.1s;
      animation-fill-mode: forwards;
      //animation-timing-function: ease-out;
      animation-direction: reverse;
    }
  }

}