.TimePicker {

  .prompt-content {
    max-width: 360px;
  }

  .btn {
    line-height: 1;
    border: none;
    font-size: 0.9em;
    background: var(--button);
    padding: 6px 8px;
    margin: 0 8px 8px 0;
    transition: all 0.2s;
    color: var(--text-primary);
  }
  .btn:active, .btn.active {
    background: var(--accent);
    color: white;
  }

  .quick-dates {
    padding: 12px 12px 4px;
  }

  .day-picker {
    min-height: 250px;
    .rdp {
      margin: 0;
      width: 100%;
      button {
        border: none;
      }
      .rdp-months {
        width: 100%;
        .rdp-month {
          flex-grow: 1;
          .rdp-caption {
            padding: 0 6px 0 12px;
          }
          .rdp-caption_label {
            padding: 0;
            font-size: 1em;
            font-weight: 500;
          }
          .rdp-table {
            width: 100%;
            max-width: none;
            .rdp-cell {
              text-align: center;
              height: 28px;
            }
            .rdp-head_cell, .rdp-day {
              max-width: none;
              width: 24px;
              height: 24px;
              line-height: 24px;
            }
            .rdp-day {
              margin: 0 auto;
              font-size: 0.8em;
            }
            .rdp-day_today {
            }
            .rdp-button:active, .rdp-button:focus {
              background: var(--accent);
              border: none;
            }
            .rdp-day_selected {
              background: var(--accent);
            }
          }
        }
      }
    }
  }

  .time-picker {
    padding: 0 4px 0 12px;
    .hour, .minutes {
      margin-bottom: 12px;
      .label {
        font-weight: 500;
        font-size: 0.9em;
        margin-bottom: 8px;
      }
      .options {
        display: flex;
        flex-wrap: wrap;
        .btn {
          flex: 1 0 0;
          font-size: 0.8em;
        }
      }
    }
  }

  .actions {
    background: rgba(0,0,0,0.1);
    padding: 12px;
    text-align: right;
    .btn {
      font-size: 0.9em;
      line-height: 1.2;
      padding: 4px 8px;
      transition: 0.2s all;
      margin: 0;
      background: var(--accent);
      color: white;
    }
    .btn-confirm {
      min-width: 80px;
    }
    .btn-text{
      background: none;
      border: none;
      color: var(--accent);
      border-radius: 0;
      padding: 0;
      margin-right: 12px;
      border-bottom: 1px dashed var(--accent);
    }
  }
}