.SearchCandidates {
  .candidate-group {
    .candidate-group-name {
      padding: 12px;
      font-weight: 400;
      font-size: 0.8em;
      text-transform: uppercase;
      opacity: 0.8;
    }
    .candidate-items {

    }
  }
}