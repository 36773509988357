.SearchTab {
  .search-instruction {
    opacity: 0.8;
    padding: 12px;
    font-size: 0.9em;
    line-height: 1.3;

    p {
      margin: 0 0 1em;
    }

    .btn-text {
      background: none;
      padding: 0;
      color: var(--accent);
      border: none;
      border-bottom: 1px dashed var(--accent);
      margin: 0 0 8px 0;
    }
  }
}