.App {
  margin: 0 auto;
  transition: all 0.2s;

  .app-loading {
    padding: 16px;
    opacity: 0.5;
  }

  .dashboard {
  }
}
