.ChangePassword {
  .form-group {
    label {
      display: block;
      margin-bottom: 8px;
    }
    input {
      padding: 6px 8px;
      border-radius: 4px;
      border: 1px solid var(--button);
      outline: none;
      width: 100%;
      box-sizing: border-box;
      font-size: 1rem;

      &:disabled {
        background: #ccc;
      }
    }
  }
}