@keyframes promptOverlayFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes promptSlideDown {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.Prompt {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: 6000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .prompt-overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;

    background: rgba(0,0,0,0.8);
  }

  .prompt-content {
    position: relative;
    z-index: 100;
    max-width: 500px;
    background: var(--background);
    border-radius: 4px;
    margin: 0 12px;
    max-height: 90vh;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    .prompt-header {
      padding: 4px 12px;
      background: rgba(0,0,0,0.3);
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        opacity: 0.9;
        font-size: 1em;
        margin: 0;
        font-weight: 500;
      }

      .close {
        cursor: pointer;
        color: inherit;
        background: transparent;
        border: none;
        padding: 0;
        font-size: 1.8em;
        font-weight: 300;
        opacity: 0.8;
        text-shadow: none;
        z-index: 20;
      }
    }

    .prompt-inner-content {
      overflow-y: auto;
      flex-grow: 1;
    }
  }

  &.entering {
    .prompt-overlay {
      animation: promptOverlayFadeIn 0.2s;
    }
    .prompt-content {
      animation: promptSlideDown 0.2s;
    }
  }

  &.exiting {
    .prompt-overlay {
      animation: promptOverlayFadeIn 0.2s;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }
    .prompt-content {
      animation: promptSlideDown 0.2s;
      animation-direction: reverse;
      animation-fill-mode: forwards;
    }
  }
}
