.home-tab {

  .section {
    .section-header {
      margin: 12px 12px 12px;
      font-size: 1em;
      font-weight: 300;
      opacity: 0.4;
    }
  }

  @keyframes app-bar-dashboard-refresh-rotate {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(359deg);}
  }

  .app-bar-action.loading{
    animation: app-bar-refresh-rotate 1.5s infinite;
    animation-timing-function: linear;
  }
}
