.ColorPicker {
  display: flex;
  align-items: center;
  .picker {
    margin-right: 12px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-sizing: border-box;

    &.active {
      border: 3px solid var(--text-primary);
    }
  }
}