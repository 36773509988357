.class {
  display: flex;
  align-items: center;
  margin-bottom: 1px;
  background-color: var(--class);
  padding-right: 12px;
  transition: all 0.2s;

  &.waitlisted {
    background-color: var(--class-waitlisted);
  }

  &.booked {
    background-color: var(--class-booked);
  }

  &.suspended {
    background-color: var(--class-suspended);
  }

  &.loading {
    opacity: 0.6;
  }

  .class-detail {
    display: none;
  }

  &.expanded {
    .class-detail {
      display: block;
    }
  }

  .class-time {
    font-size: 0.85em;
    font-weight: 500;
    flex: 0 0 50px;
    padding-left: 12px;
  }

  .class-body {
    padding: 6px 0;
    flex-grow: 1;
    line-height: 1.2;
    .class-missing {
      color: red;
      font-size: 0.85em;
      margin-bottom: 4px;
    }
    .class-name {
      font-weight: 500;
      display: flex;
      align-items: center;
      .name {

      }
      .class-attended {
        vertical-align: middle;
        font-size: 16px;
        color: #279b18;

        margin-right: 4px;
      }
    }
    .class-staff-name, .class-location, .class-date-time {
      font-size: 0.85em;
      opacity: 0.7;
    }
    .class-staff-name {
      opacity: 1;
    }
    .class-location {
      margin-top: 8px;
    }
    .class-date-time {
      margin-bottom: 8px;
    }
  }
  .class-quota {
    flex: 0 0 0;
    justify-content: flex-end;
    font-size: 0.8em;
    display: flex;
    font-weight: 500;
    color: #388e3c;

    .class-quota-full {
      color: #e53935;
    }

    .class-quota-remaining.negative {
      color: #ff9800;
    }

    .class-quota-divider {
      margin: 0 1px;
    }
  }

  .class-collapsible-icon>span {
    font-size: 16px;
    vertical-align: middle;
    margin-top: -2px;
    margin-left: 3px;
    margin-right: -4px;
    opacity: 0.7;
  }

  .class-actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    .Button {
      margin-bottom: 6px;
    }
    .ScheduleButton {
      margin-right: 6px;
    }
  }

  &.started {
    .class-name-and-staff, .class-location-date-time,
    .class-time, .class-quota, .class-collapsible-icon
    {
      opacity: 0.4;
    }
  }

  @keyframes class-entering {
    0% {
      max-height: 0;
      opacity: 0;
    }

    100% {
      max-height: 90px;
      opacity: 1;
    }
  }

  &.entering {
    .class-detail {
      max-height: 0;
      overflow: hidden;
      animation: class-entering 0.2s;
      animation-fill-mode: forwards;
      //animation-timing-function: ease-out;
    }
  }

  &.leaving {
    .class-detail {
      max-height: 50px;
      overflow: hidden;
      animation: class-entering 0.2s;
      animation-fill-mode: forwards;
      //animation-timing-function: ease-out;
      animation-direction: reverse;
    }
  }
}