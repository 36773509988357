.app-bar {
  background: var(--app-bar);
  color: var(--app-bar-text);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  min-height: 52px;

  .logo {
    font-family: "Lobster", sans-serif;
    font-size: 1.3em;
  }

  .app-bar-actions {
    .app-bar-action {
      border: none;
      background: none;
      color: inherit;
      padding: 0;
      &>span {
        vertical-align: middle;
      }
    }
  }
}
