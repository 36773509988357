.BookButton {
  background: #388e3c;
  color: white;
  font-size: 0.8em;

  &.waitlist {
    background: #ff9800;
    color: black;
  }

  &.booked {
    background: #e53935;
  }
}