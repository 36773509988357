.Confirm {
  .prompt-inner-content{

    .question {
      padding: 12px;
      font-size: 0.9em;
      line-height: 1.3;
      p {
        margin: 0 0 1em;
      }
      p:last-child {
        margin: 0;
      }
    }
    .double-confirm {
      border-top: 1px solid rgba(255,255,255,0.2);
      padding-top: 12px;
      font-weight: 600;
      font-size: 0.9em;
      margin: -6px 12px 12px;
      transition: all 0.2s;
      .form-check {
        display: flex;
        align-items: center;
        .form-check-input {
          margin-top: -1px;
        }
        .form-check-label {
          margin-left: 6px;
          color: red;
        }
      }

      &.confirmed {
        .form-check-label {
          color: inherit;
          font-weight: 400;
        }
        //background: rgba(255,255,255,0.1);
      }
    }

    .actions {
      background: rgba(0,0,0,0.1);
      padding: 12px;
      text-align: right;
      .btn {
        font-size: 0.9em;
        line-height: 1.2;
        padding: 4px 8px;
        transition: 0.2s all;
        color: inherit;
      }
      .btn-confirm {
        margin-left: 12px;
      }
      .btn-text{
        background: none;
        border: none;
        color: var(--accent);
        border-radius: 0;
        padding: 0;
        border-bottom: 1px dashed var(--accent);
      }
      .btn-danger {
        background: #e53935;
        border-color: #e53935;
        color: white;
      }
      .btn:disabled {
        opacity: 0.5;
      }
    }
  }

  &.loading {
    .prompt-content {
      opacity: 0.5;
    }
  }
}