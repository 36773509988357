.SearchAppBar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .btn {
    background: none;
    padding: 0;
    border: none;
    color: inherit;
  }

  .search-control {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 9px;
    transition: all 0.2s;

    .search {
      flex-grow: 1;
      position: relative;
      .search-icon {
        position: absolute;
        left: 7px;
        top: 7px;
        opacity: 0.5;
        &>span {
          font-size: 20px;
        }
      }
      .clear-icon {
        position: absolute;
        right: 7px;
        top: 7px;
        opacity: 0.7;
        &>span {
          font-size: 20px;
        }
      }
      .search-input {
        border: none;
        background: rgba(0,0,0,0.2);
        color: inherit;
        outline:none;
        padding: 8px 30px 8px 32px;
        border: 0;
        border-radius: 4px;
        width: 100%;
        box-sizing: border-box;
        &::placeholder {
          color: inherit;
          opacity: 0.6;
        }
      }
    }
    .time-picker {
      margin-left: 12px;
      .icon {
        vertical-align: middle;
      }
    }
  }

  .filters-section {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .filters {
      flex: 1 0 0;
      margin: 8px 0 4px 0;
      display: flex;
      flex-wrap: wrap;
    }
    .refresh {
      margin-top: 9px;
      margin-right: 4px;
    }
  }

  @keyframes app-bar-refresh-rotate {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(359deg);}
  }


  &.loading {
    .filters-section, .search-control{
      opacity: 0.5;
    }
    .filters-section .refresh .btn .icon {
      animation: app-bar-refresh-rotate 1.5s infinite;
      animation-timing-function: linear;
    }
  }


}