.bottom-navigation {
  background: var(--bottom-navigation);
  display: flex;
  align-items: center;
  padding: 6px 8px 18px;

  .button {
    user-select: none;
    background: none;
    border: none;
    color: inherit;
    flex: 1 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    -webkit-tap-highlight-color: rgba(201, 224, 253, 0);
    .icon {
      //margin-bottom: 4px;
    }
    .lbl {
      font-size: 0.7em;
    }

    &:active, &:focus {
      background: transparent;
    }

    &.active {
      color: var(--accent);
    }
  }
}