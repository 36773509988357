.Day {
  .day-header{
    line-height: 1.2;
    font-size: 0.9em;
    font-weight: 500;
    padding: 6px 16px 6px 8px;
    background: var(--day);
    margin-bottom: 1px;
    position: -webkit-sticky;
    position: sticky;
    top: 52px;
    z-index: 5;
    border-left: 4px solid var(--accent);

    display: flex;
    align-items: center;
    .day {
      color: var(--accent);
    }
    .date {
      margin-left: 6px;
      color: var(--text-primary);
    }
  }
}
